package components

import kotlinx.browser.window
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLInputElement
import react.FC
import react.Props
import react.dom.events.MouseEvent
import react.dom.html.InputMode
import react.dom.html.InputType
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h2
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.p
import utils.FormValidator
import utils.addClasses

external interface InfoPageProps: Props{
    var message: String
    var title: String
    var background: String
    var imgUrl: String?
}
external interface ServicePageProps: InfoPageProps {
    var inputId: String
    var inputLabel: String
    var inputType: InputType
    var inputMode: InputMode
    var buttonTitle: String
    var onClickButton: (MouseEvent<HTMLDivElement, *>) -> Unit
}

val InfoPage = FC<InfoPageProps>{
    Background{
        isBlurred = true
        backgroundPath = it.background
    }
    Logo{}
    div{
        addClasses("info-container")
        if(it.imgUrl != null){
            img{
                src = it.imgUrl
                alt = "Information Image"
            }
        }
        h2{
            addClasses("title")
            +it.title
        }

        p{
            addClasses("main-text")
            +it.message
        }

        Button{
            title = "Volver"
            variant = ButtonVariant.WHITE_AND_BLACK
            href = window.location.origin
        }
    }
}

val ServicePage = FC<ServicePageProps>{
    Background{
        isBlurred = true
        backgroundPath = it.background
    }
    Logo{}
    Toaster{}
    Loader{
        id = "service_page_loader"
    }
    div{
        addClasses("info-container")
        if(it.imgUrl != null){
            img{
                src = it.imgUrl
                alt = "Information Image"
            }
        }
        h2{
            addClasses("title")
            +it.title
        }

        p{
            addClasses("main-text")
            +it.message
        }
        Input{
            label = it.inputLabel
            id = it.inputId
            type = it.inputType
            mode = it.inputMode
            if (it.inputType == InputType.password){
                onInput = {
                    val target = it.target as HTMLInputElement
                    FormValidator.checkPasswordOnElement(target)
                }
            }
        }
        if (it.inputType == InputType.password){
            VisibleErrorText{
                id="eight_chars"
                message = "La contraseña tiene al menos 8 letras o numeros"
            }
            VisibleErrorText{
                id="uppercase"
                message = "La contraseña tiene una mayúscula"
            }
            VisibleErrorText{
                id="special_character"
                message = "La contraseña contiene un caracter especial."
            }
            VisibleErrorText{
                id="sixteen_chars"
                message = "La contraseña es tiene una longitud menor de 16 caracteres"
            }
            VisibleErrorText{
                id="digit"
                message = "Contiene, al menos, un número"
            }
        }
        Button{
            title = it.buttonTitle
            variant = ButtonVariant.WHITE_AND_BLACK
            onClickBehavior = it.onClickButton
        }
    }
}