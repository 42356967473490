package utils.classes

import Gender

object NordicNameGenerator {
    private val maleNames = listOf(
        "Arnbjorn", "Bjorn", "Dag", "Egil", "Freyr", "Gunnar", "Harald", "Ingvar", "Jorund", "Kjell",
        "Leif", "Magnus", "Njord", "Olaf", "Ragnar", "Sigurd", "Thor", "Ulf", "Vali", "Yngvar", "Aki",
        "Bard", "Einar", "Finn", "Grim", "Hakon", "Ivar", "Jokull", "Ketil", "Loki", "Njal", "Orm",
        "Pall", "Rolf", "Sigmund", "Thrain", "Ulfar", "Vidar", "Asgeir", "Bolli", "Dagr", "Eilif",
        "Fornjot", "Gest", "Hrothgar", "Ivarr", "Jarl", "Kolbein", "Leikr", "Muninn", "Oli", "Porunn",
        "Rurik", "Steinar", "Thrand", "Ullr", "Valbrand", "Ymir", "Aegir", "Baldr", "Danr", "Eirik",
        "Fafnir", "Gunnbjorn", "Haldor", "Ingi", "Jari", "Kormak", "Lief", "Mord", "Nott", "Olvir",
        "Porr", "Rafn", "Sigbjorn", "Thorkell", "Urd", "Valthjof", "Yngvi", "Alvis", "Bragi", "Dufniall",
        "Eilifr", "Fastulv", "Gautrek", "Hedin", "Illugi", "Jon", "Kalf", "Logi", "Narfi", "Otkel",
        "Poul", "Regin", "Sigvard", "Thrainn", "Ulfgar", "Valgard", "Yngve", "Aslak"
    )

    private val femaleNames = listOf(
        "Astrid", "Brynhild", "Dagny", "Edda", "Freyja", "Gudrun", "Helga", "Ingrid", "Jorunn", "Kari",
        "Lifa", "Mjoll", "Nanna", "Olof", "Ragnhild", "Sigrid", "Thora", "Unn", "Vedis", "Yrsa", "Alfhild",
        "Bergthora", "Eilif", "Gerd", "Hildr", "Ingibjorg", "Jofrid", "Kona", "Lofn", "Matilda", "Nidhogg",
        "Oddny", "Pernille", "Ragnild", "Sif", "Thorgerd", "Urd", "Valdis", "Ylva", "Aud", "Bera", "Disa",
        "Eir", "Fjola", "Greta", "Hjordis", "Idun", "Jarnsaxa", "Karin", "Lina", "Maren", "Njola", "Olrun",
        "Prunhilda", "Rannveig", "Sigyn", "Thordis", "Unnr", "Vilda", "Asgerd", "Bodil", "Drifa", "Eisa",
        "Frior", "Gisli", "Hervor", "Irpa", "Jorvik", "Ketta", "Lovisa", "Nott", "Oda", "Pora", "Rinda",
        "Svala", "Thyra", "Ulfhild", "Vedis", "Yngva", "Alva", "Bega", "Dagny", "Edda", "Frigg", "Gyda",
        "Hildigunn", "Ida", "Jodis", "Kenna", "Leva", "Milda", "Nera", "Ormhild", "Pora", "Rilla", "Svanhild",
        "Thyri", "Una", "Vedis", "Yngva"
    )

    fun generateName(gender: Gender): String{
        return when(gender){
            Gender.MALE -> "${maleNames.random()} ${maleNames.random()}sson"
            Gender.FEMALE -> "${femaleNames.random()} ${maleNames.random()}dóttir"
        }
    }
}