package components

import react.FC
import react.Props
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import utils.addClasses
import utils.classes.ImageLink

external interface SliderProps : Props {
    var imageList: List<ImageLink>
}

val LogoSlider = FC<SliderProps>{
    div{
        addClasses("slider")
        div{
            addClasses("slides")
            for (i in 1..2){
                for (j in 0..<it.imageList.size){
                    div{
                        addClasses("slide")
                        a{
                            href = it.imageList[j].url
                            img{
                               src = it.imageList[j].src
                               alt = it.imageList[j].alt
                            }
                        }
                    }
                }
            }
        }
    }
}