val CLASS_SECTION_CONTENT = "section-content"
val MAP_OF_NAVIGATION = mutableMapOf(
    "Los novios" to "Brúðhjónin",
    "La ubicación" to "Staðurinn",
    "Información" to "Upplýsingar",
    //"Cultura" to "Menning",
    //"PRUEBAS" to "Próf",
    //"Media" to "miðla",
    "Contacta" to "samband"
)
val NAVIGATION_WITH_IDS = mapOf(
    "Los novios" to "/#bride_groom",
    "La ubicación" to "/#location",
    "Información" to "/#information",
    //"Cultura" to "/#culture",
    //"PRUEBAS" to "/#trials",
    //"Media" to "/#media",
    "Contacta" to "/#contact"
)
val NAVIGATION_WITH_IDS_MOBILE = mapOf(
    "Los novios" to "/#bride_groom",
    "La ubicación" to "/#location",
    "Información" to "/#information",
    //"Cultura" to "/#culture",
    //"PRUEBAS" to "/#trials",
    //"Media" to "/#media",
    "Contacta" to "/#contact",
    "Perfil" to "/users/profile",
    "Salir" to "/log-out"
)

val MAPS_API_KEY = "AIzaSyA1T4wuYxKddIDtsUVnZ7cDsDjlNyLSlQM"
val WEATHER_ENDPOINT = "/weather"
val MSG_WRONG_COOKIES = "Algo raro ha ocurrido, parece que Loki ha hecho de las suyas. Por favor intenta de nuevo el proceso desde la web."
val ALLERGIES = listOf("Altramuces", "Apio", "Cacahuetes", "Crustaceos", "FrutosCascara", "Gluten", "Huevos", "Moluscos", "Pescado", "Sesamo", "Soja", "Sulfitos")


enum class FontTypes(val fontName: String){
    ODINSON("Odinson"),
    NJORD("Njord")
}

enum class TimerUnit(val plural: String, val singular: String){
    DAY("días", "día"),
    HOUR("horas","hora"),
    MINUTE("minutos","minuto"),
    SECOND("segundos", "segundo");

    fun getValueByQty(qty: UnitQuantity) = if (qty == UnitQuantity.PLURAL) plural else singular
}

enum class UnitQuantity{
    PLURAL, SINGULAR;

    companion object{
       fun getUnitByNumber(number: Int) = if (number == 1) SINGULAR else PLURAL
    }
}

enum class Gender{
    MALE,
    FEMALE
}

enum class EventType(val eventName: String){
    ON_LOADED_DOM("DOMContentLoaded")
}

enum class GlobalMessageSeverity{
    WARN,
    ERROR,
    SUCCESS
}

