package components

import csstype.url
import dto.ArticleLinkDTO
import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h3
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.p
import utils.addClasses

external interface ArticleLinkComponentProps: Props {
    var articleLink: ArticleLinkDTO
    var buttonTitle: String
}

val ArticleLink = FC<ArticleLinkComponentProps>{
    div {
        addClasses("article-link")
        div {
            addClasses("background-title-container")
            div {
                css {
                    backgroundImage = url(it.articleLink.bannerLink)
                }
                addClasses("blurred-image")
            }
            div {
                addClasses("icon-title")
                img {
                    src = it.articleLink.iconLink
                }
                h3 {
                    addClasses("title", "center-text")
                    +it.articleLink.title
                }
            }
        }
        p {
            addClasses("main-text", "justify-text")
            +(it.articleLink.description ?: "")
        }
        Button {
            title = it.buttonTitle
            variant = ButtonVariant.BLACK_AND_WHITE
            href = it.articleLink.href
            isWide = true
        }
    }
}

