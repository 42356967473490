package components
import FontTypes
import csstype.NamedColor
import csstype.url
import emotion.react.css
import react.ChildrenBuilder
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import utils.addClasses

external interface InfoCardProps: Props {
    var title: String
    var subtitle: String?
    var backgroundImgPath: String
    var iconPath: String?
    var textInfo: (ChildrenBuilder) -> ChildrenBuilder
    var button: FC<ButtonProps>?
    var roundedHeader: Boolean
}

val InfoCard = FC<InfoCardProps>{
    div{
        addClasses("info-card drop-shadow")
        if (it.roundedHeader) addClasses("rounded-header")
        div{
            addClasses("info-card-header")
            div{
                css{
                    backgroundImage = url(it.backgroundImgPath)
                }
                addClasses("blurred-image")
            }
            if (it.iconPath != null){
                img{
                    src = it.iconPath
                }
            }
            Title{
                header = it.title
                subtitle = it.subtitle ?: ""
                headerFont = FontTypes.NJORD.fontName
                subtitleFont = FontTypes.ODINSON.fontName
                headerColor = NamedColor.whitesmoke
                subtitleColor = NamedColor.whitesmoke
            }

        }

        div{
            addClasses("info-card-content")
            it.textInfo(this)

            if (it.button != null){
                it.button
            }
        }
    }
}

