package components
import dto.WeatherDTO
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import react.FC
import react.Props
import react.dom.html.ReactHTML
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h3
import react.dom.html.ReactHTML.p
import react.useEffectOnce
import react.useState
import utils.addClasses
import utils.getWeather

external interface WeatherProps: Props {
    var weatherDTOList: List<WeatherDTO>
}

external interface WeatherWidgetProps: Props{
    var weatherDTO: WeatherDTO
}

val scope = MainScope()

val WeatherIcon = FC<WeatherIconProps>{
    div{
        addClasses("weather-icon")
        ReactHTML.i { addClasses(classes = it.classes.toTypedArray()) }
        p{
            +it.value
        }
    }
}

val WeatherWidget = FC<WeatherWidgetProps>{
    div{
        addClasses("weather-widget")
        p{
            addClasses("grotesk")
            +it.weatherDTO.atHour
        }
        div{
            addClasses("weather-icon-group")
            WeatherIcon{
                classes = listOf("bi", "bi-wind")
                value = "${it.weatherDTO.windSpeed} ${it.weatherDTO.windUnit}"
            }
            WeatherIcon{
                classes = listOf("bi", "bi-cloud-rain-heavy")
                value = "${it.weatherDTO.precipitationProbability} ${it.weatherDTO.precipitationUnit}"
            }
            WeatherIcon{
                classes = listOf("bi", "bi-thermometer-half")
                value = "${it.weatherDTO.temperature} ${it.weatherDTO.temperatureUnit}"
            }
        }
    }
}
external interface WeatherIconProps: Props{
    var classes: List<String>
    var value: String
}

val Weather = FC<WeatherProps>{
    var weatherDTOList by useState(it.weatherDTOList)

    useEffectOnce {
        scope.launch {
            weatherDTOList = getWeather()
        }
    }

    div{
        addClasses("weather", "section-widget", "white-font")
        h3{
            addClasses("title")
            if(weatherDTOList.first().isWeddingDate){
                p{
                    +"Las"
                    OdinSpan{ text = " nornir "}
                    +"nos dicen como se va a comportar"
                    OdinSpan{ text = " Þórr "}
                    +"el día de nuestra boda"
                }
            } else {
                p{
                    OdinSpan{ text= "Urðr " }
                    +"no sabe aun como se comportará"
                    OdinSpan{ text = " Þórr."}
                }
            }
        }

        div{
            addClasses("weather-group")
            weatherDTOList.forEach {
                WeatherWidget{
                    weatherDTO = it
                }
            }
        }
        if(!weatherDTOList[0].isWeddingDate){
            h3{
                addClasses("title")
                +" Pronto podrás conocer como se comportará el día de nuestra boda."
            }
        }

    }
}