package components
import TimerUnit
import UnitQuantity
import kotlinx.js.timers.setTimeout
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h3
import react.dom.html.ReactHTML.h4
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span
import react.useEffect
import react.useState
import utils.addClasses
import kotlin.js.Date

external interface TimerProps: Props {
    var days: Int
    var hours: Int
    var minutes: Int
    var seconds: Int
    var millis: Int
}

external interface SquareTimerProps: Props{
    var number: Int
    var unit: TimerUnit
}

val SquareTimer = FC<SquareTimerProps>{
    div{
        h3{
            +it.unit.getValueByQty(UnitQuantity.getUnitByNumber(it.number))
        }
        addClasses("square-timer")
        p{
            +it.number.toString()
        }
    }
}

val Timer = FC<TimerProps>{
    var day by useState(0)
    var hour by useState(0)
    var minute by useState(0)
    var second by useState(0)

    useEffect {
        setTimeout({
            val timeZone = if(Date().getMonth() in 3..8) "02:00" else "01:00"
            val millisBetweenDates = (Date.parse("2024-09-21T19:00:00+$timeZone") - Date.now())
            val seconds = (millisBetweenDates / 1000).toInt()
            day = (seconds / (3600 * 24))
            hour = ((seconds % (3600 * 24)) / 3600)
            minute = (seconds % 3600 / 60)
            second = (seconds % 3600 % 60)
        }, 1000)
    }

    div{
        addClasses("timer section-widget")
        h3{
            addClasses("title")
            span{
                +"A las 19:00"
            }
        }
        h4{
            addClasses("subtitle")
            +"Solamente quedan"
        }
        div{
            addClasses("timers")
            SquareTimer{
                number = day
                unit = TimerUnit.DAY
            }
            SquareTimer{
                number = hour
                unit = TimerUnit.HOUR
            }
            SquareTimer{
                number = minute
                unit = TimerUnit.MINUTE
            }
            SquareTimer{
                number = second
                unit = TimerUnit.SECOND
            }
        }
    }
}