package components
import NAVIGATION_WITH_IDS
import NAVIGATION_WITH_IDS_MOBILE
import csstype.ClassName
import kotlinx.browser.document
import kotlinx.dom.addClass
import kotlinx.dom.removeClass
import kotlinx.js.timers.setTimeout
import org.w3c.dom.*
import react.FC
import react.Props
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.nav
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.ul
import react.useState
import utils.*

external interface NavigationProps: Props{
    var logoPath: String
    var navigationMap : Map<String, String>
}

external interface SidenavProps : NavigationProps {
    var type: SidenavType
    var withLoginButton: Boolean?
    var mobile: Boolean?
}

external interface SidenavWindowProps : Props{
    var id: String
}


val Navigation = FC<NavigationProps>{
    val navigationMap by useState(it.navigationMap)
    val logoPath by useState(it.logoPath)
    Toaster{}
    Modal{
        id = "login_register_modal"
        content = LoginRegister
    }
    nav{
        className = ClassName("nav")
        div{
            className = ClassName("logo-container")
            a{
                href = "#main_section"
                img{
                    id = "logo"
                    src = logoPath
                }
            }
        }
        ul{
            navigationMap.forEach {
                li{
                    a{
                        href = NAVIGATION_WITH_IDS[it.key]
                        +it.key
                        onMouseOver = { event -> event.currentTarget.innerText = it.value }
                        onMouseOut = { event -> event.currentTarget.innerText = it.key }
                    }
                }
            }
            li{
                if (document.cookie.isBlank()){
                    Button{
                        iconPath = getImage("VikingIconHutDoor.png")
                        variant = ButtonVariant.WHITE_AND_BLACK
                        title = "Entrar"
                        onClickBehavior = {
                            getElementAs<HTMLDialogElement>("#login_register_modal").showModal()
                        }
                    }
                }else{
                    div{
                        addClasses("user-menu")
                        p{
                            +"Bienvenido, ${getCookie("name")}"
                        }
                        img{
                            src = getCookie("photo")
                            onClick = {event ->
                                val div = (event.target as HTMLImageElement).parentElement as HTMLDivElement
                                div.classList.toggle("visible")
                            }
                        }
                        div{
                            addClasses("dropdown")
                            a{
                                +"Perfil"
                                href="/users/profile"
                            }
                            a{
                                +"Salir"
                                href="/log-out"
                            }
                        }
                    }
                }
            }
        }


    }
}

val Sidenav = FC<SidenavProps>{
    button{
        onClick = {
            getElementAs<HTMLDivElement>(".side-nav-wrapper").addClass("visible")
        }
    }
    nav{
        onMouseUp = {
            getElementAs<HTMLDivElement>(".side-nav-wrapper").removeClass("visible")
        }
        if (it.mobile == true) addClasses("mobile-nav")
        addClasses("side-nav")
        div{
            addClasses("logo-container")
                img{
                    id = "logo"
                    src = it.logoPath
                }
        }
        ul{

            it.navigationMap.forEach { entry ->
                when(it.type){
                    SidenavType.VISIBILITY -> {
                        li{
                            dataAttributes("for" to entry.value)

                            +entry.key

                            onClick ={ event ->
                                val target = event.target as HTMLLIElement
                                val element = getElementAs<HTMLDivElement>(".side-nav-window.current")
                                val forElement = document.getElementById(target.dataset["for"]!!)!!
                                if (element.id != forElement.id){
                                    element.classList.toggle("current")
                                    forElement.classList.toggle("current")
                                }
                            }
                        }
                    }
                    SidenavType.REFERENCE -> {
                        li{
                            a{
                                href = entry.value
                                +entry.key
                                onMouseUp = {
                                    getElementAs<HTMLDivElement>(".side-nav-wrapper").removeClass("visible")
                                }
                            }
                        }
                    }
                }
            }
        }

        if (it.withLoginButton == true){
            if (document.cookie.isBlank()){
                Button{
                    iconPath = getImage("VikingIconHutDoor.png")
                    variant = ButtonVariant.WHITE_AND_BLACK
                    title = "Entrar"
                    onClickBehavior = {
                        getElementAs<HTMLDialogElement>("#login_register_modal").showModal()
                        getElementAs<HTMLDivElement>(".side-nav-wrapper").removeClass("visible")
                    }
                }
            }else{
                div{
                    addClasses("mobile-user-menu")
                    p{
                        +"Bienvenido, ${getCookie("name")}"
                    }
                    img{
                        src = getCookie("photo")
                    }
                }
            }
        }
    }
}

enum class SidenavType {
    VISIBILITY,
    REFERENCE
}