package utils

import kotlinx.browser.document
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLParagraphElement
import org.w3c.dom.set
import react.dom.html.InputType

class FormValidator(validator: (FormValidator) -> Unit) {
    object ValidatorText{
        object Properties{
            const val NOT_NULL = "NOT_NULL_CONSTRAINT"
        }
        object Messages{
            const val NOT_NULL = "Debes rellenar este campo."
        }
    }

    var inputId: String = ""
    var element: HTMLInputElement? = null
    var valueNotNull: Boolean = false
    var throwMessage = false
    var regexInvalid = false
    var messageToThrow = ""
    val messages = mutableMapOf<String, String>()

    init {
        this.apply(validator)
    }
    companion object{
        fun checkPasswordOnElement(element: HTMLInputElement){
            val passwordCheck = FormValidator{
                it.element = element
            }.checkPassword()
            (document.querySelector("#eight_chars") as HTMLParagraphElement).dataset["valid"] = passwordCheck.hasAtLeastEightChars.toString()
            (document.querySelector("#uppercase") as HTMLParagraphElement).dataset["valid"] = passwordCheck.hasAnUppercaseLetter.toString()
            (document.querySelector("#special_character") as HTMLParagraphElement).dataset["valid"] = passwordCheck.hasSpecialCharacters.toString()
            (document.querySelector("#sixteen_chars") as HTMLParagraphElement).dataset["valid"] = passwordCheck.hasNoMoreThanSixteenChars.toString()
            (document.querySelector("#digit") as HTMLParagraphElement).dataset["valid"] = passwordCheck.hasADigit.toString()
        }
    }
    fun checkAndPutErrors(): Boolean{
        val input = getElementAs<HTMLInputElement>("#$inputId")
        clearMessages(input)

        var isValid = false

        if (valueNotNull){
            isValid = input.value.isNotBlank()
            if (!isValid){
                setMessage(input, ValidatorText.Properties.NOT_NULL, ValidatorText.Messages.NOT_NULL)
            }
        }

        if (throwMessage){
            isValid = false
            setMessage(input, "Forced Message", messageToThrow)
        }

        if (regexInvalid){
            isValid = false
            setMessage(input, "Regex Invalid", messageToThrow)
        }

        return isValid
    }

    fun standardValidationOnElement(): Boolean{
        return if (element != null){
            val elementToCheck = element!!
            clearMessages(elementToCheck)
            val type = elementToCheck.type
            val result = when(type){
                InputType.password.toString() -> elementToCheck.value.isNotBlank() && checkPassword().isAllGood()
                InputType.text.toString() -> {
                    if(elementToCheck.id == "password"){
                        elementToCheck.value.isNotBlank() && checkPassword().isAllGood()
                    }else{
                        elementToCheck.value.isNotBlank()
                    }
                }
                InputType.email.toString() -> """^[\w.+-_]+@[\w.-]+[.]\w{2,4}""".toRegex().matches(elementToCheck.value)
                InputType.file.toString() -> true
                else -> true
            }

            if (!result){
                setMessage(elementToCheck, "Invalid", messageToThrow)
            }

            result
        }else{
            false
        }

    }

    fun checkPassword(): PasswordChecks{
        if(element != null && (element!!.type == "password" || element!!.id == "password")){
            val elementToCheck = element!!
            val hasAtLeastEightChars = elementToCheck.value.length >= 8
            val hasNoMoreThanSixteenChars = elementToCheck.value.length <= 16
            val hasAnUppercaseLetter = "[A-Z]".toRegex().find(elementToCheck.value)?.value != null
            val hasADigit = "\\d".toRegex().find(elementToCheck.value)?.value != null
            val hasSpecialCharacters = "[\\W_]".toRegex().find(elementToCheck.value)?.value != null
            return PasswordChecks(hasAtLeastEightChars, hasNoMoreThanSixteenChars, hasAnUppercaseLetter, hasSpecialCharacters, hasADigit)
        }else{
            return PasswordChecks(false, false, false, false, false)
        }
    }

    private fun clearMessages(element: HTMLInputElement){
        messages.clear()
        val paragraph = element.getSiblingAs<HTMLParagraphElement>(".error-text")
        paragraph.style.visibility = "hidden"
        paragraph.innerText = ""
    }

    private fun setMessage(element: HTMLInputElement, property: String, message: String){
        messages[property] = message
        val paragraph = element.getSiblingAs<HTMLParagraphElement>(".error-text")
        paragraph.style.visibility = "visible"
        paragraph.innerText = message
    }

    class PasswordChecks(val hasAtLeastEightChars: Boolean, val hasNoMoreThanSixteenChars: Boolean,
                              val hasAnUppercaseLetter: Boolean, val hasSpecialCharacters: Boolean, val hasADigit: Boolean){
        fun isAllGood() = hasAtLeastEightChars && hasNoMoreThanSixteenChars && hasAnUppercaseLetter && hasSpecialCharacters && hasADigit
    }
}