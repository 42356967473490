package utils.classes

external interface ImageProps {
    var url: String
    var alt: String
    var src: String
}
class ImageLink(override var url: String = "", override var alt: String = "", override var src: String = "") : ImageProps {
    constructor(builder: (ImageLink) -> Unit): this(){
        this.apply(builder)
    }
}