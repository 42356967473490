package components
import csstype.Display
import csstype.string
import csstype.url
import emotion.react.css
import org.w3c.dom.HTMLDivElement
import react.FC
import react.Props
import react.dom.events.MouseEvent
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.p
import utils.addClasses

external interface ButtonProps: Props {
    var title: String
    var variant: ButtonVariant
    var href: String?
    var iconPath: String?
    var isWide: Boolean?
    var isTiny: Boolean?
    var hasUnicode: Boolean?
    var withLoader: Boolean?
    var onClickBehavior: ((MouseEvent<HTMLDivElement, *>) -> Unit)?
}

enum class ButtonVariant(val cssClass: String){
    BLACK_AND_WHITE("black-white"),
    WHITE_AND_BLACK("white-black")
}

val Button = FC<ButtonProps>{
    div{
        if (it.withLoader == true){
            Loader{
                id = "button-loader"
            }
        }
        if (it.iconPath != null){
            val iconPath = it.iconPath!!
            css{
                before {
                    if (iconPath.endsWith("svg")){
                        content = url(iconPath)
                        display = Display.block
                    }else{
                        content = string("""""""")
                        backgroundImage = url(it.iconPath!!)
                    }
                }
            }
            addClasses("icon-btn")
        }
        addClasses(it.variant.cssClass, "btn-viking")

        if (it.isWide == true){
            addClasses("wide")
        }  else if (it.isTiny == true){
            addClasses("tiny")
        }else{
            addClasses("medium")
        }

        if (it.hasUnicode == true){
            addClasses("html-icon")
        }

        if (it.onClickBehavior == null){
            a{
                href = it.href ?: "#"
                p{
                        +it.title
                }
            }
        }else{
            p{
                +it.title
            }
            onClick = it.onClickBehavior
        }
    }
}

