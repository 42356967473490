package dto

import kotlinx.serialization.Serializable

@Serializable
data class ProfileDTO(
    val fullName: String, val nickname: String? = null,
    val email: String,
    val isAssisting: Boolean = false, val hasToUseItsNickname: Boolean = false,
    val allergies: List<String> = listOf(),
    val isVegan: Boolean = false,
    val isVegetarian: Boolean = false,
    val needBus: Boolean = false,
    val imageData: String? = "",
    val firstName: String,
    val lastName: String
)