
import components.*
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import kotlinx.dom.addClass
import kotlinx.dom.removeClass
import org.w3c.dom.HTMLInputElement
import react.create
import react.dom.client.Root
import react.dom.client.createRoot
import react.dom.html.InputMode
import react.dom.html.InputType
import utils.*
import utils.classes.Observables

fun main() {
    println(window.location.pathname)
    when{
        window.location.pathname == "/" -> loadIndex()
        window.location.pathname.contains("/articles") -> loadArticle()
        window.location.pathname.contains("/users/sign-up/confirmation") -> loadInfoConfirmation()
        window.location.pathname.contains("/forgot-password") -> loadForgotPasswordPage()
        window.location.pathname.contains("/reset-password") -> loadResetPassword()
        window.location.pathname.contains("/sign-up") -> renderSignUp()
        window.location.pathname.contains("/forbidden") -> loadForbidden()
        window.location.pathname.contains("/email-confirmed") -> loadEmailConfirmed()
        window.location.pathname.contains("/users/profile") -> loadUserProfile()
    }
}


fun loadIndex(){
    val container = document.createElement("div")
    document.body!!.appendChild(container)

    val welcome = Welcome.create {
        name = "Kotlin/JS"
    }

    val root = createRoot(container)
    root.render(welcome)

    window.onscroll = { onScrollDoc ->
        try {
            val navigation = document.querySelector("nav.nav")!!
            if (window.scrollY > 0.0){
                navigation.addClass("header-size-medium")
            }else{
                navigation.removeClass("header-size-medium")
            }
            onScrollDoc.asDynamic()
        }catch (exception: Exception){
            console.error(exception)
        }
    }

    val observables = Observables()
    observables.toggleClassesOnIntersection(".bio-item:not(.reversed)", "slide-from-left")
    observables.toggleClassesOnIntersection(".bio-item.reversed", "slide-from-right")
    observables.toggleClassesOnIntersection("h3.title", "line-grow")
}

fun loadArticle(){
    val container = document.createElement("div")
    document.body!!.appendChild(container)
    createRoot(container).renderOnlyNavigation()
}

fun Root.renderOnlyNavigation(){
    this.render(
        Navigation.create{
        navigationMap = MAP_OF_NAVIGATION
        logoPath = utils.getImage("logo.png")
    })

    window.onscroll = { onScrollDoc ->
        try {
            val navigation = document.querySelector("nav.nav")!!
            if (window.scrollY > 0.0){
                navigation.addClass("header-size-medium")
            }else{
                navigation.removeClass("header-size-medium")
            }
            onScrollDoc.asDynamic()
        }catch (exception: Exception){
            console.error(exception)
        }
    }
}

fun renderSignUp(){
    val container = document.createElement("div")
    document.body!!.appendChild(container)
    val root = createRoot(container)
    root.render(
        StepForm.create{
            stepsContent = listOf(BasicInfoStep, PhotoNicknameStep, PasswordStep, EmailStep)
            stepDescription = listOf("Comprueba tus datos", "Elige foto y apodo", "Crea tu contraseña", "Espera tu email")
            backgroundUrl = getImage("BackgroundRegister.png")
        }
    )
}

fun loadInfoConfirmation() {
    val container = document.createElement("div")
    document.body!!.appendChild(container)
    val root = createRoot(container)


    root.render(InfoPage.create{
        title = "¡Bienvenido! Registro completado"
        message = """Se ha enviado un email a la dirección que has indicado. Ya puedes acceder al sitio pero quizás necesites confirmar el email para acceder a otros lugares de la web. Haz click en el botón más
            |abajo para volver a la web y loguearte. ¡Estamos muy contentos de verte por aquí!
        """.trimMargin()
        background = getImage("BackgroundRegister.png")
        imgUrl = getImage("VikingIconShip_white.png")
    })
}

fun loadForgotPasswordPage(){
    val container = document.createElement("div")
    document.body!!.appendChild(container)
    val root = createRoot(container)


    root.render(ServicePage.create{
        title = "Necesitamos que introduzcas tu email"
        message = """Introduce el email con el que te registraste, si existe en nuestra base de datos recibiras un email para seguir con el reseteo.
        """.trimMargin()
        background = getImage("BackgroundRegister.png")

        inputId = "email"
        inputType = InputType.email
        inputMode = InputMode.email
        inputLabel = "Email"

        buttonTitle = "Confirmar"
        onClickButton = {
            val input = getElementAs<HTMLInputElement>("#email")
            val isValid = FormValidator{validator ->
                validator.element = input
                validator.valueNotNull = true
                validator.messageToThrow = "Debes introducir un email valido."
            }.standardValidationOnElement()

            if (isValid){
                MainScope().launch {
                    submitPetitionForResetPass()
                }
            }
        }
    })
}

fun loadResetPassword(){
    val container = document.createElement("div")
    document.body!!.appendChild(container)
    val root = createRoot(container)


    root.render(ServicePage.create{
        title = "Restea tu nueva contraseña"
        message = """Introduce tu nueva contraseña, puedes comprobarla clicando en el ojo de Odin
        """.trimMargin()
        background = getImage("BackgroundRegister.png")

        inputId = "password"
        inputType = InputType.password
        inputMode = InputMode.text
        inputLabel = "Contraseña"

        buttonTitle = "Confirmar"
        onClickButton = {
            val input = getElementAs<HTMLInputElement>("#password")
            val isValid = FormValidator{validator ->
                validator.element = input
                validator.messageToThrow = "Comprueba que la contraseña cumple con lo indicado."
            }.standardValidationOnElement()

            if (isValid){
                MainScope().launch {
                    resetPass()
                }
            }
        }
    })
}

fun loadForbidden(){
    val container = document.createElement("div")
    document.body!!.appendChild(container)
    val root = createRoot(container)


    root.render(InfoPage.create{
        title = "¡403!"
        message = """Sí, sabemos que es un número muy feo, pero eso significa que no puedes acceder a este recurso o que simplemente te falta algo de información. Revisa bien y no hagas maldades.
        """.trimMargin()
        background = getImage("BackgroundRegister.png")
        imgUrl = getImage("VikingIconWoodenShield_white.png")
    })
}

fun loadEmailConfirmed(){
    val container = document.createElement("div")
    document.body!!.appendChild(container)
    val root = createRoot(container)

    root.render(InfoPage.create{
        title = "¡Email confirmado!"
        message = """Tu email ha sido confirmado, gracias por completar el registro. Ahora "c vienen cositas".
        """.trimMargin()
        background = getImage("BackgroundRegister.png")
        imgUrl = getImage("VikingIconCrow_white.png")
    })
}

fun loadUserProfile(){
    val container = document.createElement("div")
    document.body!!.appendChild(container)
    val root = createRoot(container)

    root.render(
        UserProfile.create{}
    )
}
