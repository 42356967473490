package components

import csstype.*
import emotion.react.css
import kotlinx.browser.document
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import kotlinx.dom.addClass
import kotlinx.dom.removeClass
import kotlinx.js.timers.setTimeout
import org.w3c.dom.*
import react.FC
import react.Props
import react.dom.events.ChangeEvent
import react.dom.events.FormEvent
import react.dom.html.InputMode
import react.dom.html.InputType
import react.dom.html.ReactHTML.button
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.label
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span
import react.dom.html.ReactHTML.textarea
import react.useEffect
import react.useState
import utils.addClasses
import utils.updateProperty

external interface InputProps : Props {
    var id: String
    var label: String
    var type: InputType
    var mode: InputMode
    var placeholder: String
    var iconPath: String?
    var maxLength: Int?
    var onInput: ((FormEvent<HTMLInputElement>) -> Unit)?
    var onChange: ((ChangeEvent<HTMLInputElement>) -> Unit)?
    var initialValue: String?
}
external interface EditableInputProps : InputProps {
    var editable: Boolean
}

external interface TextAreaProps : Props{
    var id: String
    var label: String
    var columns: Int
    var rows: Int
}
val Input = FC<InputProps> {
    if (it.iconPath != null) {
        div {
            css {
                position = Position.relative
                display = Display.flex
                alignItems = AlignItems.center

                before {
                    content = string("""""""")
                    backgroundImage = url(it.iconPath!!)
                    display = Display.block
                    height = 100.pct
                    width = 50.px
                    backgroundPosition = BackgroundPosition.center
                    backgroundSize = BackgroundSize.contain
                    backgroundRepeat = BackgroundRepeat.noRepeat
                }
            }
            addClasses("input-icon-container input-container")
            label{
                +it.label
                htmlFor = it.id
            }
            input {
                name = it.id
                id = it.id
                type = it.type
                inputMode = it.mode
                placeholder = it.placeholder

                if (it.onInput != null){
                    onInput = it.onInput
                }
                if (it.onChange != null){
                    onChange = it.onChange
                }
                if (it.maxLength != null){
                    maxLength = it.maxLength
                }

                if(it.initialValue != null){
                    value = it.initialValue
                }
            }
            if (it.type == InputType.password){
                span{
                    addClasses("password-eye")
                    onClick = {
                            event ->
                        (event.target as HTMLSpanElement).classList.toggle("awake")
                        val input = document.getElementById(it.id) as HTMLInputElement
                        if (input.type == "password"){
                            input.type = "text"
                        }else{
                            input.type = "password"
                        }

                    }
                }
            }
            p{
                addClasses("main-text", "error-text")
            }
        }
    }else{
        div{
            addClasses("input-container")
            label{
                +it.label
                htmlFor = it.id
            }
            input {
                name = it.id
                id = it.id
                type = it.type
                inputMode = it.mode
                placeholder = it.placeholder

                if (it.onInput != null){
                    onInput = it.onInput
                }

                if (it.maxLength != null){
                    maxLength = it.maxLength
                }

                if (it.initialValue != null){
                    value = it.initialValue
                }

                if (it.onChange != null){
                    onChange = it.onChange
                }
            }

            if (it.type == InputType.password){
                span{
                    addClasses("password-eye")
                    onClick = {
                        event ->
                        (event.target as HTMLSpanElement).classList.toggle("awake")
                        val input = document.getElementById(it.id) as HTMLInputElement
                        if (input.type == "password"){
                            input.type = "text"
                        }else{
                            input.type = "password"
                        }

                    }
                }
            }

            p{
                addClasses("main-text", "error-text")
            }
        }
    }
}

val EditableInput = FC<EditableInputProps>{
    var isEditing by useState(false)
    var inputValue by useState(it.initialValue)
    val loaderId = "${it.id}_loader"

    useEffect {
        MainScope().launch {
            println("effect")
            if (inputValue == null){
                inputValue = it.initialValue
            }
        }
    }

    div{
        addClasses("editable-input")
        Loader{
            id = loaderId
        }
        span{
            +it.label
        }
        if (!isEditing){
            p{
                addClasses("main-text")
                +(inputValue ?: "")
            }
        }else{
            input{
                id = it.id
                type= it.type
                inputMode = it.mode
                placeholder = it.placeholder
                maxLength = it.maxLength
                onInput = {
                    event ->
                    val target = event.target as HTMLInputElement
                    inputValue = target.value
                }
                onChange = it.onChange
                value = inputValue
            }
        }


        button{
            onClick = {event ->
                val button = event.target as HTMLButtonElement

                if (isEditing){
                    button.removeAttribute("editing")
                    val spinner = document.getElementById(loaderId) as HTMLDivElement
                    val input = document.getElementById(it.id) as HTMLInputElement
                    MainScope().launch {
                        updateProperty(it.id, spinner, input.value)
                    }
                }else{
                    button.setAttribute("editing", "")
                }
                isEditing = !isEditing
            }

            if (!it.editable){
                css {
                    visibility = Visibility.hidden
                }
            }

        }
    }
}

val TextArea = FC<TextAreaProps>{
    div{
        addClasses("input-container")
        label{
            +it.label
            htmlFor = it.id
        }

        textarea {
            id = it.id
            name = it.id
            cols = it.columns
            rows = it.rows
            maxLength = 255
        }

        p{
            addClasses("main-text", "error-text")
        }
    }
}