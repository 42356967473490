package components

import MAPS_API_KEY
import react.FC
import react.Props
import react.dom.html.ReactHTML.iframe
import utils.addClasses

external interface GoogleMapsProps: Props {
    var q: String
}

val EmbeddedMap = FC<GoogleMapsProps>{
    iframe{
        addClasses("drop-shadow")
        src = "https://www.google.com/maps/embed/v1/place?key=$MAPS_API_KEY&q=${it.q.replace(" ", "%20")}"
    }
}