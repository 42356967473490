package components

import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import utils.addClasses

external interface ToasterProps: Props {

}

val Toaster = FC<ToasterProps>{
    div{
        addClasses("toaster")
    }
}