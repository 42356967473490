package dto

import Gender
import kotlinx.serialization.Serializable

@Serializable
class RegistrationDTO(
    val username: String,
    val password: String,
    val fullName: String,
    val id: Int,
    val gender: Gender,
    val imageData: String? = null,
    val nickname: String? = null,
    val firstName: String,
    val lastName: String
)