package components

import csstype.Display
import csstype.FlexDirection
import csstype.NamedColor
import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.footer
import react.dom.html.ReactHTML.i
import react.dom.html.ReactHTML.span
import utils.addClasses
import utils.classes.ImageLink

external interface FooterProps : Props {

}

val Footer = FC<FooterProps> {
    footer {
        div {
            id = "urls"

            a {
                href="mailto:angelyazu@theowlandtheraven.com"
                span{
                    + "Contacta: "
                }
                +"angelyazu@theowlandtheraven.com"
            }
            div{
                a{
                    href="#"
                    i{
                        addClasses("bi", "bi-instagram")
                    }
                }
            }
        }
        div {
            id = "center"
            SimpleLogo {
                color = NamedColor.white
            }
            LogoSlider {
                imageList = listOf(
                    ImageLink {
                        it.url = "https://hidromielguerrero.com/"
                        it.src = "https://i.ibb.co/vd0qvBz/cropped-WEB-6-LOGOTIPO-HIDROMIEL-GUERRERO-450x142.png"
                        it.alt = "Hidromiel Guerrero"
                    },
                    ImageLink {
                        it.url = "https://apicolacincovillas.com/"
                        it.src = "https://i.ibb.co/Pc6SKp3/SEO-Apicola-Cinco-Villas-1.jpg"
                        it.alt = "Apícola Cinco Villas"
                    },
                    ImageLink {
                        it.url = "https://latarteria.org/"
                        it.src = "https://i.ibb.co/dgfY22f/La-Tarteria18.png"
                        it.alt = "La Tartería"
                    },
                    ImageLink {
                        it.url = "https://castillobonavia.es/"
                        it.src = "https://i.ibb.co/k097XSM/logo-bonavia.jpg"
                        it.alt = "Castillo de Bonavía"
                    }
                )
            }
        }
    }
}