package components

import react.FC
import react.Props
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span
import utils.addClasses
import utils.dataAttributes

external interface OdinFontSpan: Props {
    var text: String
}
external interface VisibleErrorTextProps: Props{
    var id: String
    var message: String
}
val OdinSpan = FC<OdinFontSpan>{
    span{
        addClasses("odin-font")
        +it.text
    }
}

val VisibleErrorText = FC<VisibleErrorTextProps>{
    p {
        id = it.id
        dataAttributes("valid" to false)
        addClasses("main-text", "check", "error", "center-text")
        span {}
        +it.message
    }
}