package components
import csstype.*
import emotion.react.css
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.launch
import org.w3c.dom.HTMLDialogElement
import org.w3c.dom.HTMLInputElement
import react.FC
import react.Props
import react.dom.aria.ariaExpanded
import react.dom.html.InputMode
import react.dom.html.InputType
import react.dom.html.ReactHTML.a
import react.dom.html.ReactHTML.dialog
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.form
import react.dom.html.ReactHTML.h2
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.small
import utils.*

external interface ModalContent: Props{}
external interface ModalProps: Props {
    var id: String
    var content: FC<ModalContent>
}

val Modal = FC<ModalProps>{
    dialog{
        Button{
            title = "X"
            isTiny = true
            variant = ButtonVariant.WHITE_AND_BLACK
            onClickBehavior = {
                getElementAs<HTMLDialogElement>("#${this@dialog.id}").close()
            }
        }
        id = it.id
        it.content{}
    }
}

val LoginRegister = FC<ModalContent>{
    Loader{
        id="modal-spinner"
    }
    div{
        id="sign_up"
        ariaExpanded = false
        Background{
            isBlurred = false
            gradient = linearGradient(
                230.deg,
                stop(rgba(105,20,13,0.5), 0.pct),
                stop(rgba(105,7,77,0.5), 100.pct)
            )
            index = 2
        }
        Background{
            isBlurred = false
            backgroundPath = getImage("RegisterImage.jpg")
            index = 1
        }
        div{
            addClasses("login-section")
            h2{
                addClasses("title")
                +"He recibido la invitación"
            }

            p{
                addClasses("main-text")
                +"Introduce el código que has recibido junto con tu carta de invitación."
            }
            form{
                id = "signup_form"
                Input{
                    id = "code"
                    label = "Inserta el código"
                    maxLength = 6
                    mode = InputMode.text
                    type = InputType.text
                    placeholder = "aAbBcC"
                    onInput = {
                        val target = (it.target as HTMLInputElement)
                        InputNormalizer(target){ normalizer ->
                            normalizer.removeSpecialsAndDigits()
                        }
                    }
                }
            }
            Button{
                title = "Registro"
                variant = ButtonVariant.WHITE_AND_BLACK
                onClickBehavior = {
                    if (document.querySelector("#sign_up")!!.getAttribute("aria-expanded") != "true"){
                        document.querySelector("#sign_up")!!.setAttribute("aria-expanded", "true")
                        document.querySelector("#sign_in")!!.setAttribute("aria-expanded", "false")
                    }else{
                        scope.launch {
                            signUpCheck()
                        }
                    }
                }
            }
        }
    }
    div{
        id="sign_in"
        ariaExpanded = true
        Background{
            isBlurred = false
            gradient = linearGradient(
                230.deg,
                stop(rgba(105,20,13,0.5), 0.pct),
                stop(rgba(105,7,77,0.5), 100.pct)
            )
            index = 2
        }
        Background{
            isBlurred = false
            backgroundPath = getImage("LoginImage.jpg")
            index = 1
        }
        div{
            addClasses("login-section")
            h2{
                addClasses("title")
                +"Bienvenido de nuevo, "
                OdinSpan{
                    text = "meðbróðir"
                }
            }
            p{
                addClasses("main-text")
                +"Si ya has introducido tu código de invitación, pulsa acceder."
            }
            form{
                id = "user_form"
                method = "post"
                action = "/users/log-in"
                Input{
                    id = "username"
                    type = InputType.email
                    mode = InputMode.email
                    iconPath = getImage("Mannaz.png")
                    placeholder = "yo@email.com"
                    label = "Email"
                }
                Input{
                    id = "password"
                    type = InputType.password
                    mode = InputMode.text
                    iconPath = getImage("Thurisaz.png")
                    placeholder = "Tu contraseña"
                    label = "Contraseña"
                }
            }
            a{
                css {
                    alignSelf = AlignSelf.end
                    color = NamedColor.whitesmoke
                }
                href="${window.location.origin}/forgot-password"
                small{
                    addClasses("main-text")
                    +"¿Has olvidado tu contraseña?"
                }
            }
            Button{
                title = "Acceder"
                variant = ButtonVariant.WHITE_AND_BLACK
                onClickBehavior = {
                    if (document.querySelector("#sign_in")!!.getAttribute("aria-expanded") != "true"){
                        document.querySelector("#sign_in")!!.setAttribute("aria-expanded", "true")
                        document.querySelector("#sign_up")!!.setAttribute("aria-expanded", "false")
                    }else{
                        try{
                            val name = FormValidator{
                                it.inputId = "username"
                                it.valueNotNull = true
                            }.checkAndPutErrors()
                            val password = FormValidator{
                                it.inputId = "password"
                                it.valueNotNull = true
                            }.checkAndPutErrors()

                            if (name && password){
                                scope.launch {
                                    loginCheck()
                                }
                            }
                        }catch(exception: Exception){
                            exception.cause.let(::println)
                        }

                    }
                }
            }
        }
    }
}