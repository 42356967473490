package utils

import WEATHER_ENDPOINT
import io.ktor.client.*
import io.ktor.client.call.*
import io.ktor.client.plugins.contentnegotiation.*
import io.ktor.client.request.*
import io.ktor.http.*
import io.ktor.serialization.kotlinx.json.*
import kotlinx.browser.window
import kotlinx.dom.addClass
import kotlinx.dom.removeClass
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLInputElement
import utils.classes.Toaster
import GlobalMessageSeverity.*
import dto.*
import io.ktor.client.statement.*
import kotlinx.browser.document
import kotlinx.js.timers.setTimeout
import kotlin.js.json

val client = HttpClient {
    install(ContentNegotiation) {
        json()
    }
}

suspend fun getWeather(): List<WeatherDTO> {
    val weatherDTO: List<WeatherDTO> = client.get(WEATHER_ENDPOINT).body()

    return weatherDTO
}

suspend fun getInfoArticles(): List<ArticleLinkDTO>{
    return client.get("/articles?tag=Información").body<List<ArticleLinkDTO>>()
}

suspend fun loginCheck() {
    val spinner = getElementAs<HTMLDivElement>("#modal-spinner")
    spinner.addClass("visible")

    val name = getElementAs<HTMLInputElement>("#username").value
    val password = getElementAs<HTMLInputElement>("#password").value
        val response = client.post{
            url("${window.location.origin}/users/log-in")
            headers{
                append(HttpHeaders.ContentType, "application/x-www-form-urlencoded")
            }
            setBody("username=$name&password=$password")
        }

        if (response.status == HttpStatusCode.OK){
            window.location.reload()
        }else{
            val errorDTO = response.body<ErrorDTO>()
            Toaster.putToast(errorDTO.message, ERROR)
        }

    spinner.removeClass("visible")
}

suspend fun signUpCheck(){
    val spinner = getElementAs<HTMLDivElement>("#modal-spinner")
    spinner.addClass("visible")

    val code = getElementAs<HTMLInputElement>("#code").value
    val response = client.post{
        url("${window.location.origin}/users/check-code")
        headers {
            append(HttpHeaders.ContentType, "application/x-www-form-urlencoded")
        }

        setBody("code=$code")
    }
    if (response.status == HttpStatusCode.OK){
        window.location.assign("${window.location.origin}/users/sign-up/$code")
    }else{
        val error = response.body<ErrorDTO>()
        FormValidator{
            it.inputId = "code"
            it.throwMessage = true
            it.messageToThrow = error.message
        }.checkAndPutErrors()
    }

    spinner.removeClass("visible")
}

suspend fun checkIfEmailExists(input: HTMLInputElement): Boolean{
    val spinner = getElementAs<HTMLDivElement>("#steps_loader")
    spinner.addClass("visible")

    val response = client.post {
        url("${window.location.origin}/users/exists")
        headers {
            append(HttpHeaders.ContentType, "application/x-www-form-urlencoded")
        }

        setBody("email=${input.value}")
    }

    spinner.removeClass("visible")
    return response.body<Boolean>();
}

suspend fun getRegistrationDto(): RegistrationDTO{
    var isLoading = false
    var response: HttpResponse? = null
    var spinner: HTMLDivElement? = null

    while (!isLoading){
        try {
            spinner = getElementAs("#steps_loader")
            spinner.addClass("visible")
            isLoading = true
            response = client.post{
                url("${window.location.origin}/users/sign-up/data")
            }
        }catch (exc: Exception){
            isLoading = false
        }
    }

    spinner!!.removeClass("visible")
    return response!!.body()
}

suspend fun submitRegistration(registrationDTO: RegistrationDTO){
    val spinner = getElementAs<HTMLDivElement>("#steps_loader")
    spinner.addClass("visible")


    val response = client.post{
        url("${window.location.origin}/users/sign-up/submit")
        header(HttpHeaders.ContentType, "application/json")
        setBody(registrationDTO)
    }

    if (response.status == HttpStatusCode.OK){
        window.location.assign("${window.location.origin}/users/sign-up/confirmation")
    }
    spinner.removeClass("visible")
}

suspend fun submitPetitionForResetPass(){
    val spinner = getElementAs<HTMLDivElement>("#service_page_loader")
    spinner.addClass("visible")

    val input = getElementAs<HTMLInputElement>("#email")
    val response = client.post{
        url("${window.location.origin}/reset")
        header(HttpHeaders.ContentType, "application/x-www-form-urlencoded")
        setBody("email=${input.value}")
    }

    spinner.removeClass("visible")
    if (response.status == HttpStatusCode.Forbidden){
        Toaster.putToast(response.body<ErrorDTO>().message, ERROR)
    }else{
        Toaster.putToast("¡Todo correcto! Si tu email existe pronto recibirás un mensaje en tu correo.", SUCCESS)
    }
}

suspend fun resetPass(){
    val spinner = getElementAs<HTMLDivElement>("#service_page_loader")
    spinner.addClass("visible")

    val input = document.getElementById("password") as HTMLInputElement
    val response = client.patch{
        url("${window.location.origin}/reset-password/submit")
        header(HttpHeaders.ContentType, "application/x-www-form-urlencoded")
        setBody("password=${input.value}")
    }

    spinner.removeClass("visible")
    if (response.status != HttpStatusCode.OK){
        Toaster.putToast("Ha habido un error al procesar la solicitud.", ERROR)
    }else{
        Toaster.putToast("Contraseña restablecida, en unos instantes se te redirigira a la página de inicio.", SUCCESS)
        setTimeout({
            window.location.assign(window.location.origin)
        }, 2000)

    }
}

suspend fun getUserInfo(): ProfileDTO{
    val spinner = getElementAs<HTMLDivElement>("#profile_spinner")
    spinner.addClass("visible")

    val userInfo = client.get {
        url("${window.location.origin}/users/info")
    }.body<ProfileDTO>()

    spinner.removeClass("visible")

    return userInfo
}

suspend fun updateProperty(property: String, spinner: HTMLDivElement, value: Any){
    spinner.addClass("visible")

    val response = client.patch {
        url("${window.location.origin}/users/profile/update")
        header(HttpHeaders.ContentType, "application/x-www-form-urlencoded")
        setBody("value=$value&property=$property")
    }

    val message = when(property){
        "nickname" -> "¡Tu apodo se ha guardado!"
        "assisting" -> if (!value.toString().toBoolean()) "Lamentamos que no puedas asistir" else "¡No podemos esperar a verte alli!"
        "bus" -> if (value.toString().toBoolean()) "¡Perfecto! Alli te esperaremos." else "¡No te preocupes, te cubrimos!"
        "allergies" -> "Tu preferencia de alergia se ha guardado."
        "vegan" -> "Tu preferencia de veganismo se ha guardado"
        "vegetarian" -> "Se ha guardado tu preferencia de vegetarianismo."
        else -> "Se ha guardado tu propiedad."
    }

    spinner.removeClass("visible")

    if (response.status == HttpStatusCode.OK){
        Toaster.putToast(message, SUCCESS)
    }else{
        Toaster.putToast("Algo ha ocurrido", ERROR)
    }
}

suspend fun sendEmailContact(spinner: HTMLDivElement, contactDTO: ContactDTO){
    val response = client.post{
        url("${window.location.origin}/contact")
        header(HttpHeaders.ContentType, "application/json")
        setBody(contactDTO)
    }

    if (response.status == HttpStatusCode.OK){
        Toaster.putToast("Tu mensaje se ha enviado correctamente", SUCCESS)
    }else{
        Toaster.putToast("Algo ha ocurrido, intentalo de nuevo más tarde", ERROR)
    }

    spinner.removeClass("visible")
}