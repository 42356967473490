package components

import Gender
import dto.RegistrationDTO
import kotlinx.browser.document
import kotlinx.coroutines.*
import kotlinx.dom.addClass
import org.w3c.dom.*
import org.w3c.files.FileReader
import react.*
import react.dom.html.InputMode
import react.dom.html.InputType
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.fieldset
import react.dom.html.ReactHTML.li
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span
import react.dom.html.ReactHTML.ul
import utils.*
import utils.classes.NordicNameGenerator

external interface StepFormProps : Props {
    var stepsContent: List<FC<StepProps>>
    var stepDescription: List<String>
    var backgroundUrl: String
}

external interface StepProps : Props {
    var userDto: RegistrationDTO?
    var title: String
}

@OptIn(DelicateCoroutinesApi::class)
val StepForm = FC<StepFormProps> {
    var isFirstStep by useState(true)
    var isLastStep by useState(it.stepsContent.size == 1)
    var parentUserDto: RegistrationDTO? by useState(null)

    useEffectOnce {
        scope.launch {
            parentUserDto = getRegistrationDto()
        }
    }
    Background {
        isBlurred = true
        backgroundPath = it.backgroundUrl
    }
    Logo{}
    div {
        Loader{
            id = "steps_loader"
        }

        addClasses("form-steps")
        ul {
            id="steps_progress"
            it.stepDescription.forEachIndexed { idx, description ->
                li {
                    if (idx == 0) addClasses("active")
                    span {
                        +description
                    }
                }
            }
        }

        it.stepsContent.forEachIndexed { idx, content ->
            div{
                addClasses("form-step")
                if (idx == 0) addClasses("current")
                content {
                    userDto = parentUserDto
                }
            }
        }



        div{
            addClasses("button-group at-bottom")
            if (!isFirstStep && it.stepsContent.isNotEmpty()){
                Button{
                    title = "Atrás"
                    variant = ButtonVariant.WHITE_AND_BLACK
                    onClickBehavior = {
                        val activeStep = getElementAs<HTMLLIElement>("li.active")
                        val activeContent = getElementAs<HTMLDivElement>("div.current")

                        isFirstStep = activeStep.previousElementSibling?.previousElementSibling == null
                        isLastStep = false

                        activeStep.togglePreviousByClass("active", "visited")
                        activeContent.togglePreviousByClass("current")
                    }
                }
            }

            if (!isLastStep && it.stepsContent.isNotEmpty()){
                Button{
                    title = "Siguiente"
                    variant = ButtonVariant.WHITE_AND_BLACK
                    onClickBehavior = {
                        GlobalScope.launch{
                            val currentInputs = getElementAs<HTMLFieldSetElement>("div.form-step.current fieldset").querySelectorAll("input")
                            val inputs = currentInputs.asList().map { node -> node as HTMLInputElement }
                            var isGood = true
                            var emailExistsIfApplies = false
                            for (input in inputs){
                                isGood = FormValidator{validator ->
                                    validator.element = input
                                    validator.messageToThrow = "Comprueba este campo"
                                }.standardValidationOnElement()

                                if (input.type == "email"){
                                    val asyncResult = GlobalScope.async {
                                        checkIfEmailExists(input)
                                    }

                                    emailExistsIfApplies = asyncResult.await()
                                }

                                if (emailExistsIfApplies){
                                    FormValidator{
                                            validator ->
                                        validator.inputId = input.id
                                        validator.throwMessage = true
                                        validator.messageToThrow = "Este email ya existe. ¿Ya te has registrado? Prueba a loguearte."
                                    }.checkAndPutErrors()
                                    break
                                }

                                if (!isGood || emailExistsIfApplies){
                                    break
                                }
                            }
                            if (isGood && !emailExistsIfApplies){
                                val activeStep = getElementAs<HTMLLIElement>("li.active")
                                val activeContent = getElementAs<HTMLDivElement>("div.current")

                                isLastStep = activeStep.nextElementSibling?.nextElementSibling == null
                                isFirstStep = false

                                activeStep.toggleNextByClass("active", "visited")
                                activeContent.toggleNextByClass("current")
                            }
                        }

                    }
                }
            }

            if (isLastStep){
                Button{
                    title = "Enviar"
                    variant = ButtonVariant.WHITE_AND_BLACK
                    onClickBehavior = {
                        val spinner = getElementAs<HTMLDivElement>("#steps_loader")
                        spinner.addClass("visible")

                        val name = document.getElementById("name") as HTMLInputElement
                        val surname = document.getElementById("surname") as HTMLInputElement
                        val username = document.getElementById("username") as HTMLInputElement
                        val nickname = document.getElementById("nickname") as HTMLInputElement
                        val password = document.getElementById("password") as HTMLInputElement

                        val photo = document.getElementById("photo") as HTMLInputElement
                        val photoFile = photo.files?.asList()?.firstOrNull()
                        val reader = FileReader()

                        if (photoFile != null){
                            reader.readAsDataURL(photoFile)
                            reader.addEventListener("load", { readerEvent ->
                                val imgValue = ((readerEvent.target as FileReader).result as? String)!!.split(",")[1]
                                val registration = RegistrationDTO(username.value, password.value, "${name.value} ${surname.value}", parentUserDto!!.id, parentUserDto!!.gender,
                                    imgValue, nickname.value, name.value, surname.value)
                                scope.launch {
                                    submitRegistration(registration)
                                }
                            })
                        }else{
                            val registration = RegistrationDTO(username.value, password.value, "${name.value} ${surname.value}", parentUserDto!!.id, parentUserDto!!.gender, null, nickname.value, name.value, surname.value)
                            scope.launch {
                                submitRegistration(registration)
                            }
                        }
                    }
                }
            }
        }

    }
}

val BasicInfoStep = FC<StepProps>{
    var name: String? by useState(null)
    var surname: String? by useState(null)
    p{
        addClasses("main-text", "center-text")
        +"Comprueba si las nornas saben todo sobre ti."
    }
    fieldset{
        Input{
            id = "username"
            type = InputType.email
            label = "Email"
            mode = InputMode.email
            placeholder = "yo@email.com"
        }
        Input{
            id = "name"
            type = InputType.text
            label = "Nombre"
            placeholder = "Bjorn"
            initialValue = name ?: it.userDto?.firstName
            onChange = {
                name = it.target.value
            }
        }
        Input{
            id = "surname"
            type = InputType.text
            label = "Apellido"
            placeholder = "Ragnarsson"
            initialValue = surname ?: it.userDto?.lastName
            onChange = {
                surname = it.target.value
            }
        }
    }
}

val PhotoNicknameStep = FC<StepProps>{
    p{
        addClasses("main-text", "center-text")
        +"Elige una imagen y un nick que haga justicia a tu alma vikinga."
    }
    fieldset{
        FileUploadInput{
            label = "Sube tu foto"
            id = "photo"
            spinnerId = "steps_loader"
        }
        Input{
            id = "nickname"
            label = "Apodo"
            placeholder = "Bjorn Ragnarsson"
            type = InputType.text
        }
        Button{
            isTiny = true
            title = "\u2685"
            variant = ButtonVariant.WHITE_AND_BLACK
            hasUnicode = true
            onClickBehavior = {
                event ->
                (document.getElementById("nickname") as HTMLInputElement).value = NordicNameGenerator.generateName(it.userDto?.gender ?: Gender.FEMALE)
            }
        }
    }
}

val PasswordStep = FC<StepProps>{
    p{
        addClasses("main-text", "center-text")
        +"Elije una contraseña, haremos lo posible para protegerla de Loki"
    }
    fieldset{
        Input{
            id = "password"
            label = "Contraseña"
            placeholder = "Tu contraseña"
            type = InputType.password
            onInput = {event ->
                val target = event.target as HTMLInputElement
                FormValidator.checkPasswordOnElement(target)
            }
        }
    }
    VisibleErrorText{
        id="eight_chars"
        message = "La contraseña tiene al menos 8 letras o numeros"
    }
    VisibleErrorText{
        id="uppercase"
        message = "La contraseña tiene una mayuscula"
    }
    VisibleErrorText{
        id="special_character"
        message = "La contraseña continee un caracter especial."
    }
    VisibleErrorText{
        id="sixteen_chars"
        message = "La contraseña es tiene una longitud menor de 16 caracteres"
    }
    VisibleErrorText{
        id="digit"
        message = "Contiene, al menos, un número"
    }
}

val EmailStep = FC<StepProps>{
    div{
        p{
            addClasses("main-text", "center-text")
            +"Un vez que le des a <<Enviar>>, te registraras y te enviaremos un email de confirmación para disfrutar de nuestra web, presiona <<Atrás>> si quieres revisar tus datos."
        }
        p{
            addClasses("main-text", "center-text")
            +"Nota: Si tras darle al botón de <<Enviar>> no te redirige a la ventana de confirmación vuelve a presionar <<Enviar>>."
        }
    }
}
