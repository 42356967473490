package components

import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import utils.addClasses

external interface SpinnerProps : Props {
    var id: String
}

val Loader = FC<SpinnerProps>{
    div{
        id = it.id
        addClasses("loader-container")
        div{
            addClasses("loader")
        }
    }
}