package components
import csstype.Color
import csstype.Gradient
import csstype.integer
import csstype.url
import emotion.react.css
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import utils.addClasses

external interface BlurredBackgroundProps: Props {
    var backgroundPath: String?
    var color: Color?
    var gradient: Gradient?
    var isBlurred: Boolean
    var index: Int
}


val Background = FC<BlurredBackgroundProps>{
    div {
        css {
            if (it.backgroundPath != null){
                backgroundImage = url(it.backgroundPath!!)
            }

            if (it.color != null){
                backgroundColor = color
            }

            if (it.gradient != null){
                background = it.gradient
            }
            zIndex = integer(it.index)
        }

        addClasses("background")

        if (it.isBlurred){
            addClasses("blurred-image")
        }
    }
}