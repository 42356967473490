package utils.classes

import kotlinx.browser.document
import kotlinx.dom.addClass
import kotlinx.dom.hasClass
import kotlinx.dom.removeClass
import kotlinx.js.ReadonlyArray
import org.w3c.dom.Element
import org.w3c.dom.IntersectionObserver
import org.w3c.dom.IntersectionObserverEntry
import org.w3c.dom.asList

class Observables {
    lateinit var observableItemsByValue: MutableMap<String, IntersectionObserver>

    fun observe(identifier: String, block: (entries: ReadonlyArray<IntersectionObserverEntry>, observer: IntersectionObserver) -> Unit) {
        val observer = IntersectionObserver(block)

        document.addEventListener(EventType.ON_LOADED_DOM.eventName,{
            val items = document.querySelectorAll(identifier)
            try{
                items.asList().forEach {
                    (it as Element).let(observer::observe)
                }
            }catch(exc: Exception){
                arrayOf(exc).let(console::error)
            }
        })

        addObservableToMap(identifier, observer)
    }

    fun toggleClassesOnIntersection(identifier: String, vararg classes: String){
        observe(identifier){
            entries, observer ->  run {
                entries.forEach {
                    if (it.isIntersecting){
                        it.target.addClass(*classes)
                    }else if(it.isIntersecting.not()){
                        it.target.removeClass(*classes)
                    }
                }
            }
        }
    }

    private fun addObservableToMap(identifier: String, observer: IntersectionObserver){
        val isMapInitialized = this::observableItemsByValue.isInitialized

        if (!isMapInitialized){
            observableItemsByValue = mutableMapOf()
            observableItemsByValue[identifier] = observer
        }else{
            observableItemsByValue[identifier] = observer
        }
    }
}