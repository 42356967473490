package components

import csstype.attr
import kotlinx.browser.document
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLInputElement
import react.FC
import react.Props
import react.dom.events.FormEventHandler
import react.dom.html.InputType
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.label
import react.dom.html.ReactHTML.span
import react.dom.onChange
import react.useEffect
import react.useState
import utils.addClasses
import utils.updateProperty

external interface InputBoxProps : Props {
    var logo: String
    var id: String
    var textFalse: String
    var textTrue: String
    var onChange: (FormEventHandler<HTMLDivElement>)?
    var isChecked: Boolean?
    var externallyController : Boolean?
}

val InputBox = FC<InputBoxProps>{
    var checkedInput: Boolean? by useState(null)
    val spinnerId = "${it.id}_spinner"
    useEffect {
        MainScope().launch {
            "${it.id} effect box".let(::println)
            if (checkedInput == null){
                checkedInput = it.isChecked
            }

            if (it.externallyController == true){
                checkedInput = it.isChecked
            }
        }
    }
    div{
        Loader{
            id = spinnerId
        }
        addClasses("input-box-container")
        span{
            +(if (checkedInput == true) it.textTrue else it.textFalse)
        }
        label{
            htmlFor = it.id
            input{
                id = it.id
                type = InputType.checkbox
                checked = checkedInput

            }
            img{
                src = it.logo
                alt = it.id
            }
        }
        if (it.onChange != null){
            onChange = it.onChange
        }else{
            onChange = { event->
                val spinner = document.getElementById(spinnerId) as HTMLDivElement
                val target = event.target as HTMLInputElement
                checkedInput = target.checked

                MainScope().launch {
                    updateProperty(target.id, spinner, target.checked)
                }
            }
        }

    }
}