package components
import CLASS_SECTION_CONTENT
import FontTypes
import csstype.*
import dto.ArticleLinkDTO
import dto.ContactDTO
import emotion.react.css
import kotlinx.browser.document
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.launch
import kotlinx.dom.addClass
import org.w3c.dom.HTMLAreaElement
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.HTMLTextAreaElement
import react.FC
import react.Props
import react.dom.html.InputMode
import react.dom.html.InputType
import react.dom.html.ReactHTML.article
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h1
import react.dom.html.ReactHTML.h2
import react.dom.html.ReactHTML.img
import react.dom.html.ReactHTML.p
import react.dom.html.ReactHTML.span
import react.useEffectOnce
import react.useState
import utils.addClasses
import utils.classes.ImageLink
import utils.getImage
import utils.getInfoArticles
import utils.sendEmailContact


external interface SectionProps : Props{
    var id : String
    var backgroundUrl: String?
    var title: Pair<String, String>?
    var content: FC<SectionContentProps>
}

external interface SectionContentProps: Props{
    var title: Pair<String, String>?
}

external interface SectionTitleProp: Props{
    var header: String?
    var subtitle: String?
    var headerColor: NamedColor?
    var subtitleColor: NamedColor?
    var subtitleFont: String?
    var headerFont : String?
    var hasShadow: Boolean
}

val Title = FC<SectionTitleProp>{
    h1{
        css {
            color = it.headerColor ?: NamedColor.black
            textShadow = TextShadow(1.px, 2.px, NamedColor.black)
            fontFamily = string(it.headerFont ?: "Njord")
            margin = 0.px
        }
        +(it.header ?: "")
    }
    h2{
        css {
            color = it.subtitleColor ?: NamedColor.black
            textShadow = TextShadow(1.px, 2.px, NamedColor.black)
            fontFamily = string(it.subtitleFont ?: "Njord")
            margin = 0.px
        }
        +(it.subtitle ?: "")
    }
}

val TitleSection = FC<SectionTitleProp>{
    div{
        addClasses(CLASS_SECTION_CONTENT, "center-items-column", "center-self", "no-inset", "no-absolute")
        if (!it.hasShadow) addClasses("no-shadow")
        Title{
            header = it.header
            subtitle = "(${it.subtitle})"
            headerFont = FontTypes.NJORD.fontName
            subtitleFont = FontTypes.ODINSON.fontName
            headerColor = it.headerColor
            subtitleColor = it.subtitleColor
        }
    }
}

val Section = FC<SectionProps>{
    div{
        id = it.id
        addClasses("scrolling-section")
        if (it.backgroundUrl != null){
            div{
                css {
                    backgroundImage = url(it.backgroundUrl!!)
                }
                addClasses("blurred-image")
            }
        }
        it.content{
            title = it.title
        }
    }
}

val MediumSection = FC<SectionProps>{
    div{
        id = it.id
        addClasses("scrolling-section", "medium")
        if (it.backgroundUrl != null){
            div{
                css {
                    backgroundImage = url(it.backgroundUrl!!)
                }
                addClasses("blurred-image")
            }
        }
        it.content{
            title = it.title
        }
    }
}

val MainSection = FC<SectionContentProps>{
    div{
        addClasses(CLASS_SECTION_CONTENT, "center-items-column", "center-self")
        Title{
            header = it.title?.first
            subtitle = it.title?.second
            subtitleColor = NamedColor.white
            headerColor = NamedColor.white
        }
        Button{
            title = "VER"
            variant = ButtonVariant.WHITE_AND_BLACK
            href = "#bride_groom"
        }
    }
}

val BrideGroom = FC<SectionContentProps>{
    div{
        addClasses(CLASS_SECTION_CONTENT)

        TitleSection{
            header = it.title?.first
            subtitle = it.title?.second
        }

        div{
            addClasses("bio-wrapper")
            div{
                addClasses("bio-item")
                id = "groom"
                img{
                    src = getImage("HornyAngel.png")
                }
                article{
                    h2{
                        addClasses("center-text")
                        span{
                            addClasses("title")
                            +"Ángel"
                        }
                        span{
                            addClasses("subtitle")
                            +" (Uglain)"
                        }
                    }
                    p{
                        addClasses("bio-description", "article-text")
                        +"El búho. Representación en la cultura nórdica de la sabiduría, la reflexión y el pensamiento. A pesar de que "
                        OdinSpan{
                            text = "Huggin"
                        }
                        +" es representado como un cuervo junto a "
                        OdinSpan{
                            text = "Munnin"
                        }
                        +" se han hallado representaciones de este como búho, muestra de que "
                        OdinSpan{
                            text = "Óðinn"
                        }
                        + """ buscaba la sabidura enterna a través de cualquier fuente. 
                            |A pesar de que es Ángel el que escribe este texto nunca aceptará que sus mayores referencias sobre la sabiduría nórdica son: 
                            |Assassin's Creed Valhalla, God of War: Rägnarok y el juego musical de VR Ragnarock.""".trimMargin()
                    }
                }
            }

            div{
                id = "bride"
                addClasses("bio-item", "reversed", "align-end")
                img{
                    src = getImage("AstonishingAzu.png")
                }
                article{
                    h2{
                        addClasses("center-text")
                        span{
                            addClasses("title")
                            +"Azucena"
                        }
                        span{
                            addClasses("subtitle")
                            +" (Hrafninn)"
                        }
                    }
                    p{
                        addClasses("bio-description", "article-text")
                        +"El cuervo. Representación de la guerra, por ello tambien se creía que recibían a los guerreros en el "
                        OdinSpan{
                            text = "Valhǫll"
                        }
                        +" y es por ello que están tambien estrechamente relacionados con las "
                        OdinSpan{
                            text = "valkyrja"
                        }
                        +""". Estas eran las que seleccionaban a los guerreros en el combate. 
                            |Los cuervos aparecían cuando la batalla acababa.
                            |Como la diosa """.trimMargin()
                        OdinSpan{
                            text = "frœyjɑ "
                        }
                        +" es madre de gatos, aunque a estos gatos si que les pone nerviosos  "
                        OdinSpan{
                            text = "Þórr"
                        }
                        +"."

                    }
                }
            }
        }
    }
}

val Location = FC<SectionContentProps>{
    div{
        addClasses(CLASS_SECTION_CONTENT)
        TitleSection{
            header = it.title?.first
            subtitle = it.title?.second
            subtitleColor = NamedColor.white
            headerColor = NamedColor.white
            hasShadow = true
        }

        div{
            addClasses("location-content")
            EmbeddedMap{
                q = "Castillo de Bonavia"
            }
            InfoCard{
                title = "Castillo de Bonavía"
                subtitle = "(árásIN)"
                textInfo = {
                    it.apply {
                        p{
                            +"""Atracamos nuestro"""
                            span{
                                addClasses("odin-font")
                                +" drakkar"
                            }
                            +""", liderados por 
                            aquel al que apodan"""
                            span{
                                addClasses("odin-font")
                                +""" Järnsida."""
                            }
                            +""" Recorrimos León, 
                                Castilla y solo unos pocos 
                                quisimos adentrarnos en 
                                el califato. Allí estaba 
                                Bonavía, la fortaleza 
                                inexpugnable."""
                        }
                    }
                }
                backgroundImgPath = getImage("BackgroundShip.jpg")
                roundedHeader = true
            }
        }
        div{
            addClasses("button-group")
            Button{
                title = "Ver en MAPS"
                variant = ButtonVariant.WHITE_AND_BLACK
                href = "https://maps.app.goo.gl/fzgtut86kvcqKahz8"
                isWide = true
                iconPath = getImage("Vegvisir.png")
            }
            Button{
                title = "Ver la web"
                variant = ButtonVariant.WHITE_AND_BLACK
                href = "https://castillobonavia.es/"
                iconPath = getImage("Valknut.png")
                isWide = true
            }
        }
    }
}

val Information = FC<SectionContentProps>{
    var infoArticles by useState<List<ArticleLinkDTO>>(listOf())
    useEffectOnce {
        scope.launch {
            infoArticles = getInfoArticles()
        }
    }

    div{
        addClasses(CLASS_SECTION_CONTENT, "center-items")
        TitleSection{
            header = it.title?.first
            subtitle = it.title?.second
        }
        div{
            addClasses("information-content")
            infoArticles.forEach {
                ArticleLink{
                    articleLink = it
                    buttonTitle = "Ver Más"
                }
            }
        }
    }
}

val ContactUs = FC<SectionContentProps>{
    div{
        addClasses(CLASS_SECTION_CONTENT)
        div{
            css{
                gap = 21.pct
                justifyContent = JustifyContent.spaceAround
            }
            addClasses("flex")

            div{
                css{
                    width = 25.pct
                    position = Position.relative
                }
                h2{
                    css{
                        position = Position.relative
                        zIndex = integer(3)
                        padding = 10.px
                    }
                    addClasses("main-text", "white")
                    +"¿Aun sigues teniendo dudas? ¿Quieres saber algo más sobre la web? ¿Sobre nuestra boda?"
                }
                p{
                    css{
                        position = Position.relative
                        zIndex = integer(3)
                        padding = 10.px
                    }
                    addClasses("main-text", "white")
                    +"No te preocupes, puedes contactar con nosotros a través del siguiente formulario."
                }
            }
            div{
                addClasses("flex", "column", "h-center")
                h2{
                    addClasses("title", "white")
                    +"Contacta con nosotros"
                }
                Input{
                    id = "contact_us_name"
                    label = "Nombre"
                    type = InputType.text
                    mode = InputMode.text
                    placeholder = "Dinos tu nombre"
                }
                Input{
                    id = "contact_us_email"
                    label = "Email"
                    placeholder = "Dejanos tu correo"
                    type = InputType.text
                    mode = InputMode.email
                }
                TextArea{
                    id = "contact_us_msg"
                    label = "Mensaje"
                    columns = 100
                    rows = 3
                }

                Button{
                    title = "Enviar"
                    variant = ButtonVariant.WHITE_AND_BLACK
                    withLoader = true
                    onClickBehavior = { event ->
                        val button = event.currentTarget

                        val spinner = button.querySelector(".loader-container") as HTMLDivElement
                        spinner.addClass("visible")

                        val name = document.getElementById("contact_us_name") as HTMLInputElement
                        val email = document.getElementById("contact_us_email") as HTMLInputElement
                        val message = document.getElementById("contact_us_msg") as HTMLTextAreaElement

                        MainScope().launch{
                            sendEmailContact(spinner, ContactDTO(email.value, name.value, message.value))
                        }
                    }
                }
            }

        }
    }
}


