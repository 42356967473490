package utils.classes

import GlobalMessageSeverity
import kotlinx.browser.document
import kotlinx.js.timers.setTimeout
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLImageElement
import org.w3c.dom.HTMLParagraphElement
import org.w3c.dom.HTMLSpanElement
import org.w3c.dom.events.Event
import utils.getElementAs
import utils.getImage
import utils.getTargetAs

object Toaster {
    fun putToast(message: String, severity: GlobalMessageSeverity){
        val toast = Toast(message, severity).element
        document.querySelector(".toaster")!!.append(toast)
        setTimeout({
            toast.style.opacity = "0"
        }, 3000)
        setTimeout({
            toast.remove()
        }, 3500)
    }

    private class Toast(val message: String, val severity: GlobalMessageSeverity){
        val element by lazy{
            val toast = document.createElement("div") as HTMLDivElement
            val toastMessage = document.createElement("p") as HTMLParagraphElement
            val toastClose = document.createElement("span") as HTMLSpanElement
            val toastTime = document.createElement("div") as HTMLDivElement
            val img = document.createElement("img") as HTMLImageElement

            toast.classList.add("toast")
            when (severity){
                GlobalMessageSeverity.WARN ->{
                    toast.classList.add("warn")
                    img.src = getImage("Ansuz.png")
                    img.alt = "Ansuz - Wisdom"
                }
                GlobalMessageSeverity.ERROR ->{
                    toast.classList.add("error")
                    img.src = getImage("Nauthiz.png")
                    img.alt = "Nauthiz - Challenge"
                }
                GlobalMessageSeverity.SUCCESS ->{
                    img.src = getImage("Nauthiz.png")
                    img.alt = "Wunjo - Success"
                    toast.classList.add("success")
                }
            }

            toastMessage.classList.add("toast-message")
            toastMessage.textContent = message

            toastClose.classList.add("toast-close")
            toastClose.innerText = "X"
            toastClose.addEventListener("click", {event: Event ->
                event.getTargetAs<HTMLSpanElement>().parentElement!!.remove()
            })
            toastTime.classList.add("toast-time")

            toast.append(img, toastMessage, toastClose, toastTime)

            toast
        }
    }
}