package components

import kotlinx.browser.document
import kotlinx.dom.addClass
import kotlinx.dom.removeClass
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.asList
import org.w3c.files.FileReader
import react.FC
import react.Props
import react.dom.html.InputType
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.input
import react.dom.html.ReactHTML.label
import react.dom.html.ReactHTML.p
import utils.addClasses

external interface FileUploadProps : Props {
    var id: String
    var label: String
    var spinnerId: String
}

val FileUploadInput = FC<FileUploadProps>{
    div{
        addClasses("input-container", "file-upload")
        label{
            +it.label
        }
        input{
            id = it.id
            name = it.id
            type = InputType.file

            addClasses("file-upload")
            onChange = {
                event ->
                val spinner = document.getElementById(it.spinnerId)
                spinner!!.addClass("visible")
                val file = event.target.files?.asList()?.first()
                if (file != null){
                    val reader = FileReader()
                    reader.readAsDataURL(file)
                    reader.addEventListener("load", { readerEvent ->

                        val parent = document.getElementById(it.id)!!.parentElement as HTMLDivElement
                        parent.classList.add("with-image")
                        parent.style.backgroundImage = "url('${(readerEvent.target as FileReader).result}')"
                        parent.style.backgroundSize = "cover"

                        spinner.removeClass("visible")
                    })

                }else{
                    val parent = event.target.parentElement
                    parent!!.classList.remove("with-image")

                    spinner.removeClass("visible")
                }

            }
        }

        p{
            addClasses("main-text", "error-text")
        }

        onClick = {
            event -> (document.getElementById(it.id) as HTMLInputElement).click()
        }
    }
}