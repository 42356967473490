package components

import MAP_OF_NAVIGATION
import NAVIGATION_WITH_IDS
import NAVIGATION_WITH_IDS_MOBILE
import dto.WeatherDTO
import kotlinx.browser.document
import react.FC
import react.Props
import react.dom.html.ReactHTML.div
import react.useState
import utils.addClasses
import utils.getImage

external interface WelcomeProps : Props {
    var name: String
}

val Welcome = FC<WelcomeProps> { props ->
    var name by useState(props.name)
    Navigation{
        navigationMap = MAP_OF_NAVIGATION
        logoPath = getImage("logo.png")
    }
    div{
        div{
            addClasses("side-nav-button")
        }
        addClasses("side-nav-wrapper")
        Sidenav{
            navigationMap = if (document.cookie.isNotBlank()) NAVIGATION_WITH_IDS_MOBILE else NAVIGATION_WITH_IDS
            logoPath = getImage("logo.png")
            mobile = true
            withLoginButton = true
            type = SidenavType.REFERENCE
        }
    }

    Section{
        id = "main_section"
        backgroundUrl = getImage("MainBackground.jpg")
        title = "Ángel y Azucena se casan." to "21-09-2024"
        content = MainSection
    }
    Section{
        id = "bride_groom"
        title = MAP_OF_NAVIGATION.map { it.key to it.value }[0]
        content = BrideGroom
    }

    Timer{}

    Section{
        id = "location"
        title = MAP_OF_NAVIGATION.map{ it.key to it.value}[1]
        content = Location
        backgroundUrl = getImage("BonaviaBackground.jpeg")
    }

    Weather {
        weatherDTOList = listOf(WeatherDTO(0, 0.0, 0.0, "km/h", "%", "C", "19:00", false))
    }

    Section{
        id = "information"
        title = MAP_OF_NAVIGATION.map{ it.key to it.value}[2]
        content = Information
    }

    MediumSection{
        id = "contact"
        content = ContactUs
    }
    Footer{}
}