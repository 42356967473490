package utils

import csstype.ClassName
import kotlinx.browser.document
import org.w3c.dom.Element
import org.w3c.dom.events.Event
import react.dom.events.NativeUIEvent
import react.dom.events.UIEvent
import react.dom.html.HTMLAttributes

fun <T: Element> HTMLAttributes<T>.addClasses(vararg classes: String){
    this.className = ClassName("${this.className?.toString() ?: ""} ${classes.joinToString(" ")}".trim())
}
fun <T: Element> getElementAs(querySelector: String): T = document.querySelector(querySelector) as T
fun <T:Element> Element.getElementAs(querySelector: String): T = this.querySelector(querySelector) as T
fun <T:Element> Element.getSiblingAs(querySelector: String): T = this.parentElement!!.querySelector(querySelector) as T
fun <T: Element> Event.getTargetAs() = this.target as T

fun HTMLAttributes<*>.dataAttributes(vararg dataValues: Pair<String, *>){
    with(this){
        dataValues.forEach {
            this.asDynamic()["data-${it.first}"] = it.second
        }
    }
}

fun Element.toggleNextByClass(selector: String, previous: String? = null){
    this.classList.toggle(selector)
    this.nextElementSibling?.classList?.toggle(selector)
    if(previous != null) this.classList.toggle(previous)
}

fun Element.togglePreviousByClass(selector: String, previous: String? = null){
    this.classList.toggle(selector)
    this.previousElementSibling?.classList?.toggle(selector)
    if (previous != null) this.previousElementSibling?.classList?.toggle(previous)

}
