package utils

import org.w3c.dom.HTMLInputElement

class InputNormalizer(val input: HTMLInputElement, builder: (InputNormalizer) -> Unit){
    init {
        this.apply(builder)
    }

    fun removeSpecialsAndDigits(){
        val regex = "[\\W_\\d]".toRegex()
        val hasSpecialCharacters = regex.findAll(input.value).toList().isNotEmpty()

        if (hasSpecialCharacters){
            input.value = input.value.replace(regex, "")
            FormValidator{
                it.inputId = input.id
                it.regexInvalid = true
                it.messageToThrow = "Solamente puedes insertar letras."
            }.checkAndPutErrors()
        }else{
            FormValidator{
                it.inputId = input.id
                it.regexInvalid = false
            }.checkAndPutErrors()
        }
    }
}