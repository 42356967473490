package components

import ALLERGIES
import dto.ProfileDTO
import dto.RegistrationDTO
import kotlinx.browser.document
import kotlinx.coroutines.MainScope
import kotlinx.coroutines.awaitAll
import kotlinx.coroutines.launch
import org.w3c.dom.HTMLDivElement
import org.w3c.dom.HTMLInputElement
import org.w3c.dom.asList
import react.*
import react.dom.html.InputMode
import react.dom.html.InputType
import react.dom.html.ReactHTML.div
import react.dom.html.ReactHTML.h2
import react.dom.html.ReactHTML.img
import utils.addClasses
import utils.getImage
import utils.getUserInfo
import utils.updateProperty

external interface UserProfilePageProps: Props{

}

val UserProfile = FC<UserProfilePageProps>{
    var userDTO by useState<ProfileDTO>()
    var allergies: Set<String>? by useState(null)
    useEffectOnce {
        MainScope().launch {
            userDTO = getUserInfo()
        }
    }

    useEffect{
        if (allergies == null){
            "list effect".let(::println)
            allergies = userDTO?.allergies?.toMutableSet()
        }
    }

    Toaster{}
    div{
        addClasses("profile-page")
        Loader{
            id = "profile_spinner"
        }

        Sidenav{
            navigationMap = mutableMapOf("Información" to "info", "Datos adicionales" to "conf")
            logoPath = getImage("logo.png")
            type = SidenavType.VISIBILITY
        }

        div{
            id = "info"
            addClasses("side-nav-window", "current")
            img{
                src = userDTO?.imageData
            }

            EditableInput{
                id = "fullName"
                label = "Nombre"
                type = InputType.text
                mode = InputMode.text
                initialValue = userDTO?.fullName
            }
            EditableInput{
                id = "nickname"
                label = "Apodo"
                type = InputType.text
                mode = InputMode.text
                initialValue = userDTO?.nickname
                editable = true
            }
            EditableInput{
                id = "email"
                label = "Correo"
                type = InputType.text
                mode = InputMode.text
                initialValue = userDTO?.email
            }

            h2{
                addClasses("title")
                +"Asistencia a la boda"
            }

            div{
                addClasses("input-group")
                InputBox{
                    id = "assisting"
                    logo = getImage("VikingIconShip_white.png")
                    textTrue ="Asistiré"
                    textFalse = "No asistiré"
                    isChecked = userDTO?.isAssisting
                }

                InputBox{
                    id = "bus"
                    logo = getImage("VikingIconShip_white.png")
                    textTrue ="Iré en coche"
                    textFalse = "Necesitaré autobús"
                    isChecked = userDTO?.needBus
                }

                InputBox{
                    id = "use_nickname"
                    logo = getImage("VikingIconHelmet_white.png")
                    textTrue ="Usar mi apodo"
                    textFalse = "No usar mi apodo"
                    isChecked = userDTO?.hasToUseItsNickname
                }
            }
        }

        div{
            id = "conf"
            addClasses("side-nav-window")

            h2{
                addClasses("title")
                +"Alergias"
            }
            div{
                addClasses("input-group")
                ALLERGIES.forEachIndexed { index, s ->
                    InputBox{
                        id = "allergy_$index"
                        logo = getImage("alergenos/$s.png")
                        textTrue ="Alergico"
                        textFalse = "No alergico"
                        if (userDTO != null){
                            isChecked = index.toString() in (allergies ?: setOf())
                        }

                        externallyController = true
                        onChange = { event ->
                            try{

                                val spinner = document.getElementById("allergy_${index}_spinner") as HTMLDivElement

                                val allCheckedAllergies = document.querySelectorAll("input[id^=allergy]")
                                    .asList().map { it as HTMLInputElement }.map { it.id.split("_")[1] to it.checked }
                                    .filter { it.second }.joinToString(";"){ it.first }

                                allergies = allCheckedAllergies.split(";").toMutableSet()

                                MainScope().launch{
                                    updateProperty("allergies", spinner, allCheckedAllergies)
                                }
                            }catch (exc: Exception){
                                exc.printStackTrace()
                                exc.cause.let(::println)
                                exc.message.let(::println)
                            }

                        }
                    }
                }
            }

            h2{
                addClasses("title")
                +"Ética culinaria"
            }
            div{
                addClasses("input-group")

                InputBox{
                    id = "vegan"
                    logo = getImage("VeganIcon_white.png")
                    textTrue ="Soy vegano"
                    textFalse = "No soy vegano"
                    isChecked = userDTO?.isVegan
                }

                InputBox{
                    id = "vegetarian"
                    logo = getImage("VeganIcon_white.png")
                    textTrue ="Soy vegetariano"
                    textFalse = "No soy vegetariano"
                    isChecked = userDTO?.isVegetarian
                }
            }
        }
    }

    fun setUserDto(dto: ProfileDTO){
        userDTO = dto
    }
}